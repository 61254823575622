<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { parseErrors } from '../../../helpers'
import Swal from "sweetalert2";
import http from '@/oauth-client'

import {
   authComputed
} from "@/state/helpers";

export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'Portfolio Solution Templates',
      items:[],
      isBusy: false,
    
      breadcrumbs: [
        {
          text: 'Portfolio Solution Templates',
          active: true
        }
      ]
    };
  },
  created(){
    this.load();
  },
  computed: {
    ...authComputed
  },
  methods: {
    async load()  {
      this.isBusy = true;
    
      const resp = await http.get(`api/portfolio-solutions/templates`, {
        params: {

        }
      });
      this.items = resp.data;
      this.isBusy = false;  
    },
    confirmDelete(item) {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
         this.handleDelete(item);
        }
      });
    },
    handleDelete(item){
      this.isBusy = true;
     http.delete(`api/portfolio-solutions/templates/${item.templateId}`).then(() => {
        this.isBusy = false;
        Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
        this.load ();

      })
      .catch(error => {
        this.errors= parseErrors(error);
        this.isBusy = false;
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
      <div class="card p-0 cardc8">
        <div class="card-content m-3">
          <div class="">  
            <div class="table-responsive mt-3" style="position: relative;">
              <table
                class="table table-centered nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead >
                  <tr>
                    <th>Template Name</th>
                    <th style="width:50px; max-width:50px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{item.title}}</td>
                    
                    <td>
                      <a
                       
                        href="javascript:void(0);"
                        class="mr-3 text-danger"
                        @click="confirmDelete(item)"
                        :disable="isBusy"
                        title="Delete"
                      >
                        <i v-if="!isBusy" class="fa fa-times font-size-18"></i>
                        <i v-if="isBusy" class="fa fa-spin fa-spinner font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="position:absolute; left:0; right:0; top:0; bottom:0; background-color: rgba(255,255,255,0.5); text-align: center; padding-top:10%;"
                v-if="isBusy">
                <span>
                  <i class="fa fa-spin fa-spinner" style="font-size:3rem"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
     
        
  </Layout>
</template>